<template>
  <div class="pagePadding">
    <div class="pageTop">
      <div class="pageBtm clearfix">
        <span class="fr pageBtn finger btnReset expoBtn" @click="output">导出</span>
      </div>
      <span class="fr pageBtn finger btnSure marginLeft20" @click="setupStatus = true">设置</span>
      <Form inline class="formMarginBtm20 clearfix" v-if="this.type == 1">
        <FormItem>
          <span>盘存人：</span>
          <i-input class="iviewIptWidth250 marginRight60" placeholder="请输入" :value="detailsInfo.user_name" disabled></i-input>
        </FormItem>
        <FormItem>
          <span>仓库名称：</span>
          <i-input class="iviewIptWidth250 marginRight60" placeholder="请输入" :value="detailsInfo.warehouse_name" disabled></i-input>
        </FormItem>
      </Form>
      <span class="titleTxt" v-else>{{ titleName }}</span>
      <Spin fix v-if="tabloading"></Spin>
      <Table :productList="listColumns" :productData="meterList" :option_page="option_page" highlight-row border :row-class-name="rowClassName"></Table>
      <!-- <Table :columns="type == 1 ? listColumns : listColumns2" :row-class-name="rowClassName" :data="meterList" border no-data-text="暂无产品"> </Table> -->
      <div class="summary" v-if="meterList && meterList.length > 0 && type == 1">
        库存数量：<span class="color389 marginRight20">{{ quantity_total }}</span> 金额：<span class="color389 marginRight20">{{ $utils.formatMoney(amount_total, true) }}</span> 盘存数量：<span class="color389 marginRight20">{{ actual_quantity }}</span> 金额：<span class="color389">{{ $utils.formatMoney(actual_amount, true) }}</span>
      </div>
      <div class="summary" v-if="meterList && meterList.length > 0 && type == 2">
        总数量：<span class="color389 marginRight20">{{ quantity_total }}</span> 合计金额：<span class="color389">{{ $utils.formatMoney(amount_total, true) }}</span>
      </div>
      <div style="position: relative; border: 1px solid #e8eaec; height: 500px; border-top: none !important;" class="clearfix noData" v-if="meterList.length == 0">
        <img style="margin-top: 100px;" src="../../assets/images/tableNoData.png" />
      </div>
      <!-- <div class="summary">
        总盈亏：<span style="color: #2EA909" v-if="detailsInfo.loss_quantity">{{ detailsInfo.loss_quantity }}</span><span style="color: #FA3E3B" v-else>{{ detailsInfo.wins_quantity }}</span>
      </div> -->
    </div>
    <div class="pageBtm clearfix">
      <span class="pageBtn finger btnReset fr" @click="back">返回</span>
    </div>
    <!-- 动态表头弹窗 -->
    <table-setup v-if="setupStatus" :pageList="titleList" :option_page="option_page" @cancleBtn="setupStatus = false" @sureBrn="sureSetup"></table-setup>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import Table from '@/components/table'
import TableSetup from '@/components/tableSetup'
export default {
  name: 'inventoryStockDetail',
  components: {
    Table,
    TableSetup,
  },
  computed: {
    ...mapState(['clientNameList']),
  },
  data() {
    return {
      titleList: [],
      listData: [],
      option_page: '38',
      setupStatus: false,
      actual_amount: 0,
      actual_quantity: 0,
      quantity_total: 0,
      amount_total: 0,
      tabloading: false,
      titleName: '',
      detailsInfo: {},
      storeList: [],
      meterList: [], // 已选产品列表
      listColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 65,
        },
        {
          title: '产品名称',
          key: 'product_name',
          align: 'center',
          minWidth: 180,
        },
        {
          title: '产品编码',
          key: 'product_model_code',
          align: 'center',
          minWidth: 90,
        },
        {
          title: '货号/SKU',
          key: 'item_number',
          align: 'center',
        },
        {
          title: '批号',
          key: 'batch_number',
          align: 'center',
        },
        {
          title: '序列号',
          key: 'serial_number',
          align: 'center',
        },
        {
          title: '单价',
          align: 'center',
          width: 100,
          render: (h, param) => {
            return h('div', [h('span', param.row.unit_price ? '¥' + param.row.unit_price : '0')])
          },
        },
        {
          title: '库存数量',
          key: 'inventory_quantity',
          align: 'center',
          width: 100,
        },
        {
          title: '单位',
          key: 'unit',
          align: 'center',
          width: 100,
        },
        {
          title: '包装规格',
          key: 'packing_size',
          align: 'center',
          width: 100,
        },
        {
          title: '实际盘存数量',
          key: 'check_quantity',
          align: 'center',
          width: 130,
        },
      ],
      listColumns2: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 65,
        },
        {
          title: '客户',
          key: 'customer_name',
          align: 'center',
          minWidth: 250,
        },
        {
          title: '产品编号',
          key: 'product_model_code',
          align: 'center',
          minWidth: 150,
        },
        {
          title: '产品名称',
          key: 'product_name',
          align: 'center',
          minWidth: 150,
        },
        {
          title: '规格型号',
          key: 'model_name',
          align: 'center',
          minWidth: 90,
        },
        {
          title: '批号',
          key: 'batch_number',
          align: 'center',
          minWidth: 150,
        },
        {
          title: '生产日期',
          key: 'production_date',
          align: 'center',
          minWidth: 150,
        },
        {
          title: '有效期',
          key: 'valid_period',
          align: 'center',
          minWidth: 150,
        },
        {
          title: '数量',
          key: 'quantity',
          align: 'center',
          minWidth: 150,
        },
        {
          title: '销售单价',
          align: 'center',
          minWidth: 150,
          render: (h, param) => {
            return h('div', [h('span', param.row.sale_unit_price ? '¥' + param.row.sale_unit_price : '0')])
          },
        },
      ],
      listColumns3: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 65,
        },
        {
          title: '供应商',
          key: 'supplier_name',
          align: 'center',
          minWidth: 250,
        },
        {
          title: '产品编号',
          key: 'product_model_code',
          align: 'center',
          minWidth: 150,
        },
        {
          title: '产品名称',
          key: 'product_name',
          align: 'center',
          minWidth: 150,
        },
        {
          title: '规格型号',
          key: 'model_name',
          align: 'center',
          minWidth: 90,
        },
        {
          title: '批号',
          key: 'batch_number',
          align: 'center',
          minWidth: 100,
        },
        {
          title: '生产日期',
          key: 'production_date',
          align: 'center',
          minWidth: 150,
        },
        {
          title: '有效期',
          key: 'valid_period',
          align: 'center',
          minWidth: 150,
        },
        {
          title: '数量',
          key: 'quantity',
          align: 'center',
          minWidth: 150,
        },
        {
          title: '采购单价',
          align: 'center',
          minWidth: 150,
          render: (h, param) => {
            return h('div', [h('span', param.row.unit_price ? '¥' + param.row.unit_price : '0')])
          },
        },
      ],
      id: '',
      type: '', // 详情类型  1 盘存  2 报溢报损
    }
  },
  mounted() {
    this.queryList()
    this.id = this.$route.query.id
    this.type = this.$route.query.type
    if (this.$route.query.order_type == 1) {
      // this.$nextTick(() => {
      this.listColumns2 = this.listColumns3
    }
    this.queryOption()
    this.queryInventoryDetail()
  },
  methods: {
    sureSetup(val) {
      this.queryOption()
    },
    // 获取表格表头
    queryOption() {
      this.$http
        .get(this.$api.systemOptionItem, { option_page: this.option_page })
        .then(res => {
          this.titleList = res.data.enable
          localStorage.setItem('adminTtitleList', JSON.stringify(res.data.list))
          for (let i = 0; i < this.titleList.length; i++) {
            if (this.titleList[i].show) {
              this.titleList[i].key = this.titleList[i].data_col
              this.titleList[i].width = this.titleList[i].column_width
              this.titleList[i].align = 'center'
              this.titleList[i].resizable = true
            }
          }
          this.listColumns = []
          this.listColumns.unshift({
            title: '序号',
            type: 'index',
            align: 'center',
            width: 60,
          })
          this.listColumns = [...this.listColumns, ...this.titleList]
          this.listColumns = JSON.parse(JSON.stringify(this.listColumns))
        })
        .catch(() => {
          // this.queryStoreList()
        })
    },
    // 导出
    output() {
      let data = {
        id: this.$route.query.id,
      }
      this.$http.downFile(this.$api.inventory_outDetail_export, data).then(res => {
        let blob = new Blob([res.data], { type: 'application/x-xlsx' })
        const aLink = document.createElement('a')
        aLink.href = URL.createObjectURL(blob)
        aLink.download = '库存盘存导出表.xlsx'
        aLink.click()
      })
    },
    rowClassName(row, index) {
      if (row.inventory_quantity > row.check_quantity) {
        return 'greenRow'
      } else if (row.inventory_quantity < row.check_quantity) {
        return 'redRow'
      } else {
        return ''
      }
    },
    back() {
      this.$router.go(-1)
    },
    queryInventoryDetail() {
      this.tabloading = true
      let api = null
      let obj = {}
      if (this.type == 1) {
        api = this.$api.queryInventoryDetail
        obj = {
          id: this.id,
        }
      } else if (this.type == 2) {
        // 报溢报损记录listColumns2
        api = this.$api.SpilloverOrderDetail
        obj = {
          spillover_order_number: this.id,
        }
      }
      this.$http.get(api, obj, true).then(res => {
        this.tabloading = false
        this.detailsInfo = res.data
        this.amount_total = res.data.total_amount
        this.quantity_total = res.data.total_number
        this.meterList = res.data.line_info
        if (this.type == 1) {
          this.actual_amount = res.data.actual_amount
          this.actual_quantity = res.data.actual_quantity
          this.meterList.forEach(item => {
            // 判断是录入的是年月还是年月日
            item.production_date = item.production_date ? this.$moment.unix(item.production_date).format('YYYY-MM-DD HH:mm:ss') : null
            // item.production_date = item.production_date ? this.$utils.Timetransformation(item.production_date).replace(/-/gi, '') : null
            item.valid_period = item.valid_period ? this.$moment.unix(item.valid_period).format('YYYY-MM-DD HH:mm:ss') : null
            item.licence_valid_period = item.licence_valid_period ? this.$moment.unix(item.licence_valid_period).format('YYYY-MM-DD HH:mm:ss') : null
            item.production_license_valid_period = item.production_license_valid_period ? this.$moment.unix(item.production_license_valid_period).format('YYYY-MM-DD HH:mm:ss') : null
            // item.valid_period = item.valid_period ? this.$utils.Timetransformation(item.valid_period).replace(/-/gi, '') : null
          })
        }
        if (this.type == 2) {
          this.titleName = res.data.order_type_str + '清单'
          this.meterList.forEach(item => {
            // 判断是录入的是年月还是年月日
            item.production_date = item.production_date ? this.$moment.unix(item.production_date).format('YYYY-MM-DD HH:mm:ss') : null
            item.production_date = item.production_date ? this.$utils.Timetransformation(item.production_date).replace(/-/gi, '') : null
            item.valid_period = item.valid_period ? this.$moment.unix(item.valid_period).format('YYYY-MM-DD HH:mm:ss') : null
            item.valid_period = item.valid_period ? this.$utils.Timetransformation(item.valid_period).replace(/-/gi, '') : null
          })
        }
      })
    },
    // 查询仓库列表
    queryList() {
      this.isLoad = true
      let data = {
        warehouse_name: '',
        page: 1,
        rows: 1000,
      }
      this.$http.get(this.$api.permissionWarehouseList, data, true).then(res => {
        this.storeList = res.data
      })
    },
  },
}
</script>

<style scoped lang="less">
.pagePadding {
  display: flex;
  flex-direction: column;
  .pageTop {
    flex: 1;
    overflow-y: auto;
  }
  .pageBtm {
    float: right;
    padding-top: 25px;
  }
}
.marginRight60 {
  margin-right: 60px;
}
.marginRight50 {
  margin-right: 55px;
}
.danjia {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.iviewIptWidth270 {
  width: 290px !important;
}
.titleTxt {
  font-size: 16px;
  font-weight: 500;
  color: #525b6d;
  display: inline-block;
  margin: 15px 0px;
}
.expoBtn {
  position: absolute;
  top: 87px;
  left: 850px;
}
</style>
